import Notification from "../../components/Notification";
import React, {useEffect, useRef, useState} from "react";
import {useViewport} from "../../hooks";
import {widthGTAAsideSize} from "../../utils";
import ItemAside from "../../components/ItemAside";
import AsiayunAds from "../../components/AsiayunAds";
import Ads from "../../components/Ads";
import {useSelector} from "react-redux";
import {getUserIsVip} from "../../redux/user";
import Sticky from "react-stickynode";

function Aside({isFollowPage}) {
    let width = useViewport()
    const isVip = useSelector(getUserIsVip)
    const asideRef = useRef();
    const [divHeight, setDivHeight] = useState(0);

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            for(let entry of entries) {
                if (entry.target === asideRef.current) {
                    setDivHeight(entry.contentRect.height);
                }
            }
        });

        if (asideRef.current) {
            observer.observe(asideRef.current);
        }
        return () => {
            if (asideRef.current) {
                observer.unobserve(asideRef.current);
            }
        }
    }, []);

    if (!widthGTAAsideSize(width)) return null;

    return (
        <aside ref={asideRef} className="ml-4 hidden asideSize:block w-[270px]">
            <div className="flex flex-col h-full items-stretch space-y-5">
                <Notification/>
                <AsiayunAds/>
                <Ads type={1}/>
                <Sticky top={20} innerZ={1} bottomBoundary={divHeight + 80} >
                    <div className="h-full pb-5">
                        <div className="space-y-5">
                            {isFollowPage && isVip && <ItemAside type={2}/>}
                            <ItemAside type={1}/>
                        </div>
                    </div>
                </Sticky>
            </div>
        </aside>
    );
}

export default Aside;
